import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import defaultSettings from '@/settings'
const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    dept: '',
    id: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_DEPT: (state, dept) => {
      state.dept = dept
    },
    SET_ID: (state, id) => {
      state.id = id
    },
  },

  actions: {
    setToken({ commit }, token) {
      setToken(token)
      commit('SET_TOKEN', token)
    },
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      const tenantid = userInfo.tenantid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid, tenantid).then(res => {
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
      GetInfo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(res => { 
            const user = res.user
            // debugger
            if (user.avatar && user.avatar != '') {
                user.avatar = user.avatar.startsWith("http") ? user.avatar : (process.env.VUE_APP_BASE_API + user.avatar);                
            }
          const avatar = user.avatar == "" ? require("@/assets/image/profile.jpg") : user.avatar;
          if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.roles)
            commit('SET_PERMISSIONS', res.permissions)
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT'])
          }
          commit('SET_NAME', user.userName)
          commit('SET_AVATAR', avatar)
          commit('SET_DEPT', user.dept?user.dept.deptName:'')
          commit('SET_ID', user.userId)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
      LogOut ({ commit, state }) {
        // debugger
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeToken()
          resolve()
            if (defaultSettings.casEnable) {
                window.location.href = defaultSettings.caslogoutUrl                 
            }                    
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
