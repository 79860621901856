<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews() {   
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100% - 50px);
  width: 100%;
  position: relative;
  padding:20px;
  box-sizing: border-box;
  overflow: hidden; 
  color: #42558C;
}

.fixed-header+.app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 102 = navbar + tags-view = 50 + 52 */
    min-height: calc(100% - 102px); 
  } 
  .fixed-header+.app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
// 需 -padding
.app-main>.height-full,
.app-main>.main-height-full{ 
    min-height: calc(100vh - 98px);
}
.hasTagsView .app-main>.height-full,
.hasTagsView .app-main .main-height-full{
      min-height: calc(100vh - 150px);
}

// 需 -padding
.app-main>.process-height-full,
.app-main>.process-main-height-full{ 
    min-height: calc(100vh - 118px);
}
.hasTagsView .app-main>.process-height-full,
.hasTagsView .app-main .process-main-height-full{
      min-height: calc(100vh - 170px);
}
</style>
