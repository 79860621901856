var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      class: { "tags-process": _vm.isProcess },
      attrs: { id: "tags-view-container" },
    },
    [
      _vm._v(" " + _vm._s(_vm.activeRoute) + " "),
      _vm.isProcess
        ? _c(
            "div",
            { staticClass: "process-tab" },
            _vm._l(_vm.processRoutes, function (item) {
              return _c(
                "div",
                {
                  key: item.path,
                  staticClass: "tab-item",
                  class: { active: item.path == _vm.$route.path },
                  on: {
                    click: function ($event) {
                      return _vm.changeProcess(item.path)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }