// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"variables.scss"}]);
// Exports
exports.locals = {
	"menuText": "#E4EFFF",
	"menuActiveText": "#0047BE",
	"subMenuActiveText": "#0047BE",
	"menuBg": "linear-gradient(225deg, #0063DF 0%, #0E6FE2 55%, #02CFED 100%)",
	"menuHover": "linear-gradient(225deg, #0063DF 0%, #0E6FE2 55%, #02CFED 100%)",
	"subMenuBg": "linear-gradient(180deg, #00EEFF 0%, #00B9F6 100%)",
	"subMenuHover": "linear-gradient(180deg, #00EEFF 0%, #00B9F6 100%)",
	"sideBarWidth": "208px"
};
module.exports = exports;
