module.exports = {
  title: '电子档案管理系统',

  /**
   * 是否系统布局配置
   */
  showSettings: false,

  /**
   * 是否显示 tagsView
   */
  tagsView: true,

  /**
   * 是否固定头部
   */
  fixedHeader: false,

  /**
   * 是否显示logo
   */
  sidebarLogo: true,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
    errorLog: 'production',

    /**
     * 开启cas
     */
    casEnable: JSON.parse(process.env.VUE_APP_CAS_CASENABLE),

  /**
   * 单点登录url
   */
  casloginUrl: process.env.VUE_APP_CAS_HOST_LOGIN+'?service='+process.env.VUE_APP_CAS_APP_HOST,

  /**
   * 单点登出url
   */
  caslogoutUrl: process.env.VUE_APP_CAS_HOST_LOGOUT+'?service='+process.env.VUE_APP_CAS_APP_HOST
}
