<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default  {
  name:  'App'
}
</script>
<style>
.el-tooltip__popper{
  width: 500px !important;
}
</style>
