import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken,setToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login','/login2', '/auth-redirect', '/bind', '/register','/cas/sso','/chart']
const defaultSettings = require('@/settings.js')
function getLevel(data,prepath) {
  for (let i = 0; i < data.length; i++) {
    if(data[i].hidden) continue;
    if (data[i].children) {
      prepath+=data[i].path=='/'?data[i].path:(data[i].path+'/');
      return getLevel(data[i].children,prepath);
    } else {
      return prepath+=data[i].path;
      break;
    }
  }
}
let path='';
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getToken()) {
        // to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    }
    // else if (defaultSettings.casEnable && to.path === '/casUI') {
    //     debugger
    //   let ticket = to.query.jwt
    //   setToken(ticket)
    //   //commit('SET_TOKEN', ticket)
    //   window.location.href = "/index"
    //   NProgress.done()
    // }
    else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(res => {
          // 拉取user_info
          const roles = res.roles
          store.dispatch('GenerateRoutes', { roles }).then(accessRoutes => {
          // 测试 默认静态页面
          // store.dispatch('permission/generateRoutes', { roles }).then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            path=getLevel(accessRoutes,'');
            if(to.path=='/index'||to.path=='/'){//该固定首页路由已经去掉
              next({path, replace: true }) // hack方法 确保addRoutes已完成
            }else{
              next({...to, replace: true }) // hack方法 确保addRoutes已完成
            }
          })
        })
          .catch(err => {
            store.dispatch('FedLogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        if(to.path=='/index'||to.path=='/'){//该固定首页路由已经去掉
          next({path, replace: true }) // hack方法 确保addRoutes已完成
        }
        next()
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        // if (hasPermission(store.getters.roles, to.meta.roles)) {
        //   next()
        // } else {
        //   next({ path: '/401', replace: true, query: { noGoBack: true }})
        // }
        // 可删 ↑
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    }
    // else if (defaultSettings.casEnable && to.path === '/casUI') {
    else if (defaultSettings.casEnable && to.query.jwt && to.query.jwt!='') {
      console.log(to,to.query,from)
    //   debugger
      let ticket = to.query.jwt
      setToken(ticket)
      //commit('SET_TOKEN', ticket)
      window.location.href = "/"
      NProgress.done()
    }
    else {
            //开启cas
            if (defaultSettings.casEnable) {
                window.location.href = defaultSettings.casloginUrl // 否则全部重定向到登录页
            }else{
              let path=`/login?redirect=${to.fullPath}`;
              to.query.tenantid&&(path+="&tenantid="+to.query.tenantid)
              next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            }

      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
